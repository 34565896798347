import EmptyRouterView from '@/views/components/empty-router-view/EmptyRouterView'

export default [

  // ======================== LAYOUT =======================//
  {
    path: '',
    component: () => import('@/views/layouts/Master'),
    meta: { authRequired: true },
    children: [
      /** ******************************** DASHBOARD ******************************* **/
      {
        path: '/',
        name: 'index',
        redirect: 'dashboard'
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/pages/dashboard/Dashboard'),
        meta: {
          title: 'Dashboard',
          noPageContentTitle: true
        }
      },

      /** ******************************* MASTER DATA ****************************** **/
      {
        path: 'master',
        component: EmptyRouterView,
        children: [
          {
            path: 'company',
            name: 'master.company',
            component: () => import('@/views/pages/master/company/Company'),
            meta: {
              title: 'Company',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Company', active: true }
              ],
              can: ['view_company']
            }
          },
          {
            path: 'profesi',
            name: 'master.profesi',
            component: () => import('@/views/pages/master/profesi/Profesi'),
            meta: {
              title: 'Profesi',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Profesi', active: true }
              ],
              can: ['view_profesi_customer']
            }
          },
          {
            path: 'sumberInfo',
            name: 'master.sumberInfo',
            component: () => import('@/views/pages/master/sumber-info/SumberInfo'),
            meta: {
              title: 'Sumber Info',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Sumber Info', active: true }
              ],
              can: ['view_sumber_info']
            }
          },
          {
            path: 'mediaPlatform',
            name: 'master.mediaPlatform',
            component: () => import('@/views/pages/master/media-platform/MediaPlatform'),
            meta: {
              title: 'Media Platform',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Media Platform', active: true }
              ],
              can: ['view_media_platform']
            }
          },
          {
            path: 'jenisRekanan',
            name: 'master.jenisRekanan',
            component: () => import('@/views/pages/master/jenis-rekanan/JenisRekanan'),
            meta: {
              title: 'Jenis Rekanan',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Jenis Rekanan', active: true }
              ],
              can: ['view_jenis_rekanan']
            }
          },
          {
            path: 'itemPerijinanWarga',
            name: 'master.itemPerijinanWarga',
            component: () => import('@/views/pages/master/item-perijinan-warga/ItemPerijinanWarga'),
            meta: {
              title: 'Item Perijinan Warga',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Item Perijianan Warga', active: true }
              ],
              can: ['view_item_perijinan_warga']
            }
          },
          {
            path: 'currency',
            name: 'master.currency',
            component: () => import('@/views/pages/master/currency/Currency'),
            meta: {
              title: 'Currency',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Currency', active: true }
              ],
              can: ['view_currency']
            }
          },
          {
            path: 'coa',
            name: 'master.coa',
            component: () => import('@/views/pages/master/coa/Coa'),
            meta: {
              title: 'COA',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'COA', active: true }
              ],
              can: ['view_coa']
            }
          },
          {
            path: 'coa/setProyek',
            name: 'master.coa.setProyek',
            component: () => import('@/views/pages/master/coa/CoaSetProyek'),
            meta: {
              title: 'COA Set Proyek',
              parent: 'master.coa.setProyek', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'COA', url: { name: 'master.coa' } },
                { title: 'Set Proyek', active: true }
              ]
            }
          },
          {
            path: 'itemIpl',
            name: 'master.itemIpl',
            component: () => import('@/views/pages/master/item-ipl/ItemIpl'),
            meta: {
              title: 'Item IPL',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Item IPL', active: true }
              ],
              can: ['view_item_ipl']
            }
          },
          {
            path: 'itemSales',
            name: 'master.itemSales',
            component: () => import('@/views/pages/master/item-sales/ItemSales'),
            meta: {
              title: 'Item Jasa Estate',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Item Sales', active: true }
              ],
              can: ['view_item_sales']
            }
          },
          {
            path: 'itemKebersihan',
            name: 'master.itemKebersihan',
            component: () => import('@/views/pages/master/item-kebersihan/ItemKebersihan'),
            meta: {
              title: 'Item Kebersihan',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Item Kebersihan', active: true }
              ],
              can: ['view_item_kebersihan']
            }
          },
          {
            path: 'itemIpl/setTarif',
            name: 'master.itemIpl.setTarif',
            component: () => import('@/views/pages/master/item-ipl/ItemIplSetTarif'),
            meta: {
              title: 'Set Item IPL Tarif',
              parent: 'master.itemIpl', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Item IPL', url: { name: 'master.itemIpl' } },
                { title: 'Set Item IPL Tarif', active: true }
              ]
            }
          },
          {
            path: 'itemPengadaan',
            name: 'master.itemPengadaan',
            component: () => import('@/views/pages/master/item-pengadaan/ItemPengadaan'),
            meta: {
              title: 'Item Pengadaan',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Item Pengadaan', active: true }
              ],
              can: ['view_item_pengadaan']
            }
          },
          {
            path: 'itemRab',
            name: 'master.itemRab',
            component: () => import('@/views/pages/master/item-rab/ItemRab'),
            meta: {
              title: 'Item RAB',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Item RAB', active: true }
              ],
              can: ['view_item_rab']
            }
          },
          {
            path: 'itemKpr',
            name: 'master.itemKpr',
            component: () => import('@/views/pages/master/item-kpr/ItemKpr'),
            meta: {
              title: 'Item KPR',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Item KPR', active: true }
              ],
              can: ['view_item_kpr']
            }
          },
          {
            path: 'itemKpr/setPerBankKpr',
            name: 'master.itemKpr.setPerBankKpr',
            component: () => import('@/views/pages/master/item-kpr/ItemKprSetPerBankKpr'),
            meta: {
              title: 'Set Item KPR Per Bank',
              parent: 'master.itemKpr', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Item KPR', url: { name: 'master.itemKpr' } },
                { title: 'Set Item KPR Per Bank', active: true }
              ]
            }
          },
          {
            path: 'itemDokumenCustomer',
            name: 'master.itemDokumenCustomer',
            component: () => import('@/views/pages/master/item-dokumen-customer/ItemDokumenCustomer'),
            meta: {
              title: 'Item Dokumen Customer',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Item Dokumen Customer', active: true }
              ],
              can: ['view_item_dokumen_customer']
            }
          },
          {
            path: 'itemDokumenCustomer/setPerProfesi',
            name: 'master.itemDokumenCustomer.setPerProfesi',
            component: () => import('@/views/pages/master/item-dokumen-customer/ItemDokumenCustomerSetPerProfesi'),
            meta: {
              title: 'Item Dokumen Customer',
              parent: 'master.itemDokumenCustomer',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Item Dokumen Customer', url: { name: 'master.itemDokumenCustomer.setPerProfesi' } },
                { title: 'Set Per Profesi', active: true }
              ]
            }
          },
          {
            path: 'itemDokumenProyek',
            name: 'master.itemDokumenProyek',
            component: () => import('@/views/pages/master/item-dokumen-proyek/ItemDokumenProyek'),
            meta: {
              title: 'Item Dokumen Proyek',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Item Dokumen Proyek', active: true }
              ],
              can: ['view_item_dokumen_proyek']
            }
          },
          {
            path: 'itemGroup1',
            name: 'master.itemGroup1',
            component: () => import('@/views/pages/master/item-group1/ItemGroup1'),
            meta: {
              title: 'Item Group 1',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Item Group 1', active: true }
              ],
              can: ['view_item_group_1']
            }
          },
          {
            path: 'itemGroup2',
            name: 'master.itemGroup2',
            component: () => import('@/views/pages/master/item-group2/ItemGroup2'),
            meta: {
              title: 'Item Group 2',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Item Group 2', active: true }
              ],
              can: ['view_item_group_2']
            }
          },
          {
            path: 'itemGroup3',
            name: 'master.itemGroup3',
            component: () => import('@/views/pages/master/item-group3/ItemGroup3'),
            meta: {
              title: 'Item Group 3',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Item Group 3', active: true }
              ],
              can: ['view_item_group_3']
            }
          },
          {
            path: 'itemGroup4',
            name: 'master.itemGroup4',
            component: () => import('@/views/pages/master/item-group4/ItemGroup4'),
            meta: {
              title: 'Item Group 4',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Item Group 4', active: true }
              ],
              can: ['view_item_group_4']
            }
          },
          {
            path: 'itemProgressPekerjaan',
            name: 'master.itemProgressPekerjaan',
            component: () => import('@/views/pages/master/item-progress-pekerjaan/ItemProgressPekerjaan'),
            meta: {
              title: 'Item Progress Pekerjaan',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Item Progress Pekerjaan', active: true }
              ],
              can: ['view_item_progress_pekerjaan']
            }
          },
          {
            path: 'itemProgressPekerjaan/setBobot',
            name: 'master.itemProgressPekerjaan.setBobot',
            component: () => import('@/views/pages/master/item-progress-pekerjaan/ItemProgressPekerjaanSetBobot'),
            meta: {
              title: 'Item Progress Pekerjaan Set Bobot',
              parent: 'master.itemProgressPekerjaan.setBobot', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Item Progress Pekerjaan', url: { name: 'master.itemProgressPekerjaan' } },
                { title: 'Set Bobot', active: true }
              ]
            }
          },
          {
            path: 'hadiah',
            name: 'master.hadiah',
            component: () => import('@/views/pages/master/hadiah/Hadiah'),
            meta: {
              title: 'Hadiah',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Hadiah', active: true }
              ],
              can: ['view_hadiah']
            }
          },
          {
            path: 'cluster',
            name: 'master.cluster',
            component: () => import('@/views/pages/master/cluster/Cluster'),
            meta: {
              title: 'Cluster',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Cluster', active: true }
              ],
              can: ['view_properti_cluster']
            }
          },
          {
            path: 'caraBayar',
            name: 'master.caraBayar',
            component: () => import('@/views/pages/master/cara-bayar/CaraBayar'),
            meta: {
              title: 'Cara Bayar',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Cara Bayar', active: true }
              ]
            }
          },
          {
            path: 'caraBayar/setProyek',
            name: 'master.caraBayar.setProyek',
            component: () => import('@/views/pages/master/cara-bayar/CaraBayarSetProyek'),
            meta: {
              title: 'Cara Bayar Set Proyek',
              parent: 'master.caraBayar.setBobot', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Cara Bayar', url: { name: 'master.caraBayar' } },
                { title: 'Set Proyek', active: true }
              ],
              can: ['view_cara_bayar']
            }
          },
          {
            path: 'bankKpr',
            name: 'master.bankKpr',
            component: () => import('@/views/pages/master/bank-kpr/BankKpr'),
            meta: {
              title: 'Bank KPR',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Bank KPR', active: true }
              ],
              can: ['view_bank_kpr']
            }
          },
          {
            path: 'staf',
            name: 'master.staf',
            component: () => import('@/views/pages/master/staf/Staf'),
            meta: {
              title: 'Staf',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Staf', active: true }
              ],
              can: ['view_staf']
            }
          },
          {
            path: 'staf/setProyek',
            name: 'master.staf.setProyek',
            component: () => import('@/views/pages/master/staf/StafSetProyek'),
            meta: {
              title: 'Staf Set Proyek',
              parent: 'master.staf.setProyek', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Staf', url: { name: 'master.staf' } },
                { title: 'Set Proyek', active: true }
              ]
            }
          },
          {
            path: 'rekanan',
            name: 'master.rekanan',
            component: () => import('@/views/pages/master/rekanan/Rekanan'),
            meta: {
              title: 'Rekanan',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Rekanan', active: true }
              ],
              can: ['view_rekanan']
            }
          },
          {
            path: 'rekanan/setProyek',
            name: 'master.rekanan.setProyek',
            component: () => import('@/views/pages/master/rekanan/RekananSetProyek'),
            meta: {
              title: 'Rekanan Set Proyek',
              parent: 'master.rekanan.setProyek', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Rekanan', url: { name: 'master.rekanan' } },
                { title: 'Set Proyek', active: true }
              ]
            }
          },
          {
            path: 'customer',
            name: 'master.customer',
            component: () => import('@/views/pages/master/customer/Customer'),
            meta: {
              title: 'Customer',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Customer', active: true }
              ],
              can: ['view_customer']
            }
          },
          {
            path: 'propertiKategori',
            name: 'master.propertiKategori',
            component: () => import('@/views/pages/master/properti-kategori/PropertiKategori'),
            meta: {
              title: 'Properti Kategori',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Properti Kategori', active: true }
              ],
              can: ['view_properti_kategori']
            }
          },
          {
            path: 'propertiTipe',
            name: 'master.propertiTipe',
            component: () => import('@/views/pages/master/properti-tipe/PropertiTipe'),
            meta: {
              title: 'Properti Tipe',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Properti Tipe', active: true }
              ],
              can: ['view_properti_tipe']
            }
          },
          {
            path: 'propertiUnit',
            name: 'master.propertiUnit',
            component: () => import('@/views/pages/master/properti-unit/PropertiUnit'),
            meta: {
              title: 'Properti Unit',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Properti Unit', active: true }
              ],
              can: ['view_properti_unit']
            }
          },
          {
            path: 'proyek',
            name: 'master.proyek',
            component: () => import('@/views/pages/master/proyek/Proyek'),
            meta: {
              title: 'Proyek',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Master Data' },
                { title: 'Proyek', active: true }
              ],
              can: ['view_proyek']
            }
          }
        ]
      },

      /** ***********************=********* PROYEK ********************************* **/
      {
        path: 'siteplan',
        name: 'proyek.siteplan',
        component: () => import('@/views/pages/proyek/siteplan/Siteplan'),
        meta: {
          title: 'Siteplan',
          hideBreadcrumb: true,
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Proyek' },
            { title: 'Siteplan', active: true }
          ],
          can: ['view_siteplan']
        }
      },
      {
        path: 'rabProyek',
        component: EmptyRouterView,
        children: [
          {
            path: 'rabProyek',
            name: 'proyek.rabProyek',
            component: () => import('@/views/pages/proyek/rab-proyek/RabProyek'),
            meta: {
              title: 'RAB Proyek',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Proyek' },
                { title: 'RAB Proyek', active: true }
              ],
              can: ['view_rab_proyek']
            }
          }
        ]
      },
      {
        path: 'rabUnit',
        component: EmptyRouterView,
        children: [
          {
            path: 'rabUnit',
            name: 'proyek.rabUnit',
            component: () => import('@/views/pages/proyek/rab-unit/RabUnit'),
            meta: {
              title: 'RAB Unit',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Proyek' },
                { title: 'RAB Unit', active: true }
              ],
              can: ['view_rab_unit']
            }
          }
        ]
      },
      {
        path: 'spk',
        component: EmptyRouterView,
        children: [
          {
            path: '/',
            name: 'proyek.spk',
            component: () => import('@/views/pages/proyek/spk/Spk'),
            meta: {
              title: 'SPP',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Proyek' },
                { title: 'SPP', active: true }
              ],
              can: ['view_spp']
            }
          },
          {
            path: 'show/:idSpk',
            name: 'proyek.spk.show',
            component: () => import('@/views/pages/proyek/spk/SpkShow'),
            meta: {
              title: 'Detail SPP',
              parent: 'proyek.spk', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Proyek' },
                { title: 'SPP', url: { name: 'proyek.spk' } },
                { title: 'Detail SPP', active: true }
              ]
            }
          },
          {
            path: 'add',
            name: 'proyek.spk.add',
            component: () => import('@/views/pages/proyek/spk/SpkAdd'),
            meta: {
              title: 'Tambah SPP',
              parent: 'proyek.spk', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Proyek' },
                { title: 'SPP', url: { name: 'proyek.spk' } },
                { title: 'Tambah SPP', active: true }
              ]
            }
          },
          {
            path: 'edit/:idSpk',
            name: 'proyek.spk.edit',
            component: () => import('@/views/pages/proyek/spk/SpkEdit'),
            meta: {
              title: 'Edit SPP',
              parent: 'proyek.spk', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Proyek' },
                { title: 'SPP', url: { name: 'proyek.spk' } },
                { title: 'Edit SPP', active: true }
              ]
            }
          }
        ]
      },
      {
        path: 'spkRequestPayment',
        component: EmptyRouterView,
        children: [
          {
            path: '/',
            name: 'proyek.spkRequestPayment',
            component: () => import('@/views/pages/proyek/spk-request-payment/SpkRequestPayment'),
            meta: {
              title: 'SPP Request Payment',
              noScroll: true,
              noPageContentTitle: true,
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Proyek' },
                { title: 'SPP Request Payment', active: true }
              ],
              can: ['view_spp_request_payment']
            }
          }
        ]
      },
      {
        path: 'po',
        component: EmptyRouterView,
        children: [
          {
            path: '/',
            name: 'proyek.po',
            component: () => import('@/views/pages/proyek/po/Po'),
            meta: {
              title: 'PO',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Proyek' },
                { title: 'PO', active: true }
              ],
              can: ['view_purchase_order']
            }
          },
          {
            path: 'show/:idPo',
            name: 'proyek.po.show',
            component: () => import('@/views/pages/proyek/po/PoShow'),
            meta: {
              title: 'Detail PO',
              parent: 'proyek.po', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Proyek' },
                { title: 'PO', url: { name: 'proyek.po' } },
                { title: 'Detail PO', active: true }
              ]
            }
          },
          {
            path: 'add',
            name: 'proyek.po.add',
            component: () => import('@/views/pages/proyek/po/PoAdd'),
            meta: {
              title: 'Tambah PO',
              parent: 'proyek.po', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Proyek' },
                { title: 'PO', url: { name: 'proyek.po' } },
                { title: 'Tambah PO', active: true }
              ]
            }
          },
          {
            path: 'edit/:idPo',
            name: 'proyek.po.edit',
            component: () => import('@/views/pages/proyek/po/PoEdit'),
            meta: {
              title: 'Edit PO',
              parent: 'proyek.po', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Proyek' },
                { title: 'PO', url: { name: 'proyek.po' } },
                { title: 'Edit PO', active: true }
              ]
            }
          }
        ]
      },
      {
        path: 'progressProyek',
        component: EmptyRouterView,
        children: [
          {
            path: '/',
            name: 'proyek.progressProyek',
            component: () => import('@/views/pages/proyek/progress-unit/as_kontraktor/ProgressUnitAsKontraktor'),
            props: { isRouteProgressUnit: false },
            meta: {
              title: 'Progress Proyek',
              parent: 'proyek.progressProyek', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Proyek' },
                { title: 'Progress Proyek', active: true }
              ],
              can: ['view_progress_proyek']
            }
          },
          {
            path: ':idSpk/itemPekerjaan',
            name: 'proyek.progressProyek.itemPekerjaanProyek',
            component: () => import('@/views/pages/proyek/progress-unit/as_kontraktor/ProgressUnitPekerjaan'),
            props: { isRouteProgressUnit: false },
            meta: {
              title: 'Progress Proyek',
              parent: 'proyek.progressProyek', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Proyek' },
                { title: 'Progress Proyek' },
                { title: 'Item Pekerjaan Proyek', active: true }
              ]
            }
          },
          {
            path: 'history/:idSpkPekerjaan',
            name: 'proyek.progressProyek.spkPekerjaan.history',
            component: () => import('@/views/pages/proyek/progress-unit/ProgressUnitHistory'),
            props: { isRouteProgressUnit: false },
            meta: {
              title: 'History Pekerjaan',
              parent: 'proyek.progressProyek', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Proyek' },
                { title: 'Progress Proyek', url: { name: 'proyek.progressProyek' } },
                { title: 'Item Pekerjaan Proyek' },
                { title: 'History Pekerjaan', active: true }
              ]
            }
          }
        ]
      },
      {
        path: 'progressUnit',
        component: EmptyRouterView,
        children: [
          {
            path: '/',
            name: 'proyek.progressUnit',
            component: () => import('@/views/pages/proyek/progress-unit/ProgressUnit')
          },
          {
            path: 'asKontraktor',
            component: EmptyRouterView,
            children: [
              {
                path: '/',
                name: 'proyek.progressUnit.asKontraktor',
                component: () => import('@/views/pages/proyek/progress-unit/as_kontraktor/ProgressUnitAsKontraktor'),
                props: { isRouteProgressUnit: true },
                meta: {
                  title: 'Progress Unit',
                  parent: 'proyek.progressUnit', // relate to menu-items slug
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Proyek' },
                    { title: 'Progress Unit', active: true }
                  ]
                }
              },
              {
                path: ':idSpk/listUnit',
                name: 'proyek.progressUnit.asKontraktor.listUnit',
                component: () => import('@/views/pages/proyek/progress-unit/as_kontraktor/ProgressUnitListUnit'),
                meta: {
                  title: 'SPP List Unit',
                  parent: 'proyek.progressUnit', // relate to menu-items slug
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Proyek' },
                    { title: 'Progress Unit', url: { name: 'proyek.progressUnit.asKontraktor' } },
                    { title: 'SPP List Unit', active: true }
                  ]
                }
              },
              {
                path: ':idSpk/listUnit/:idPropertiUnit/itemPekerjaan',
                name: 'proyek.progressUnit.asKontraktor.itemPekerjaanUnit',
                component: () => import('@/views/pages/proyek/progress-unit/as_kontraktor/ProgressUnitPekerjaan'),
                props: { isRouteProgressUnit: true },
                meta: {
                  title: 'Item Pekerjaan Unit',
                  parent: 'proyek.progressUnit', // relate to menu-items slug
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Proyek' },
                    { title: 'Progress Unit', url: { name: 'proyek.progressUnit.asKontraktor' } },
                    { title: 'List Unit' },
                    { title: 'Item Pekerjaan Unit', active: true }
                  ]
                }
              }
            ]
          },
          {
            path: 'asTeknik',
            component: EmptyRouterView,
            children: [
              {
                path: '/',
                name: 'proyek.progressUnit.asTeknik',
                component: () => import('@/views/pages/proyek/progress-unit/as_teknik/ProgressUnitAsTeknik'),
                meta: {
                  title: 'Progress Unit',
                  parent: 'proyek.progressUnit', // relate to menu-items slug
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Proyek' },
                    { title: 'Progress Unit', active: true }
                  ]
                }
              },
              {
                path: ':idPropertiUnit',
                name: 'proyek.progressUnit.asTeknik.listSpk',
                component: () => import('@/views/pages/proyek/progress-unit/as_teknik/ProgressUnitListSpk'),
                meta: {
                  title: 'List SPP',
                  parent: 'proyek.progressUnit', // relate to menu-items slug
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Proyek' },
                    { title: 'Progress Unit' },
                    { title: 'List SPP', active: true }
                  ]
                }
              },
              {
                path: ':idPropertiUnit/listSpk/:idSpk/itemPekerjaan',
                name: 'proyek.progressUnit.asTeknik.listSpk.itemPekerjaanSpk',
                component: () => import('@/views/pages/proyek/progress-unit/as_teknik/ProgressUnitPekerjaan'),
                props: { isRouteProgressUnit: true },
                meta: {
                  title: 'Item Pekerjaan SPP',
                  parent: 'proyek.progressUnit', // relate to menu-items slug
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Proyek' },
                    { title: 'Progress Unit' },
                    { title: 'List SPP' },
                    { title: 'Item Pekerjaan', active: true }
                  ]
                }
              }
            ]
          },
          {
            path: 'history/:idSpkPekerjaan',
            name: 'proyek.progressUnit.spkPekerjaan.history',
            component: () => import('@/views/pages/proyek/progress-unit/ProgressUnitHistory'),
            props: { isRouteProgressUnit: true },
            meta: {
              title: 'History Pekerjaan',
              parent: 'proyek.progressUnit', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Proyek' },
                { title: 'Progress Unit' },
                { title: 'List Unit' },
                { title: 'Item Pekerjaan Unit' },
                { title: 'History Pekerjaan', active: true }
              ]
            }
          }
        ]
      },
      {
        path: 'stk',
        component: EmptyRouterView,
        children: [
          {
            path: 'stk',
            name: 'proyek.stk',
            component: () => import('@/views/pages/proyek/stk/Stk'),
            meta: {
              title: 'Serah Terima Kontraktor',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Proyek' },
                { title: 'STK', active: true }
              ],
              can: ['view_stk']
            }
          }
        ]
      },
      {
        path: 'progressDokumenProyek',
        component: EmptyRouterView,
        children: [
          {
            path: 'progressDokumenProyek',
            name: 'proyek.progressDokumenProyek',
            component: () => import('@/views/pages/proyek/progress-dokumen-proyek/ProgressDokumenProyek'),
            meta: {
              title: 'Progress Dokumen Proyek',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Proyek' },
                { title: 'Progress Dokumen Proyek', active: true }
              ],
              can: ['view_progress_dokumen_proyek']
            }
          }
        ]
      },
      {
        path: 'progressDokumenUnit',
        component: EmptyRouterView,
        children: [
          {
            path: 'progressDokumenUnit',
            name: 'proyek.progressDokumenUnit',
            component: () => import('@/views/pages/proyek/progress-dokumen-unit/ProgressDokumenUnit'),
            meta: {
              title: 'Progress Dokumen Unit',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Proyek' },
                { title: 'Progress Dokumen Unit', active: true }
              ],
              can: ['view_progress_dokumen_unit']
            }
          }
        ]
      },

      /** ******************************* MARKETING ******************************** **/
      {
        path: 'media',
        component: EmptyRouterView,
        children: [
          {
            path: '/',
            name: 'marketing.media',
            component: () => import('@/views/pages/marketing/media/Media'),
            meta: {
              title: 'Media',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Marketing' },
                { title: 'Media', active: true }
              ],
              can: ['view_media']
            }
          },
          {
            path: '/show/:idMedia',
            name: 'marketing.media.detail',
            component: () => import('@/views/pages/marketing/media/MediaDetail'),
            meta: {
              title: 'Media Detail',
              parent: 'marketing.media', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Marketing' },
                { title: 'Media', url: { name: 'marketing.media' } },
                { title: 'Detail', active: true }
              ],
              can: ['view_media']
            }
          }
        ]
      },
      {
        path: 'pengajuanMedia',
        component: EmptyRouterView,
        children: [
          {
            path: '/',
            name: 'marketing.pengajuanMedia',
            component: () => import('@/views/pages/marketing/pengajuan-media/PengajuanMedia'),
            meta: {
              title: 'Pengajuan Media',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Marketing' },
                { title: 'Pengajuan Media', active: true }
              ],
              can: ['view_pengajuan_media']
            }
          },
          {
            path: 'add',
            name: 'marketing.pengajuanMedia.add',
            component: () => import('@/views/pages/marketing/pengajuan-media/PengajuanMediaAdd'),
            meta: {
              title: 'Tambah Pengajuan Media',
              parent: 'marketing.pengajuanMedia', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Marketing' },
                { title: 'Pengajuan Media' },
                { title: 'Tambah Pengajuan Media', active: true }
              ]
            }
          },
          {
            path: 'edit/:idPengajuanMedia',
            name: 'marketing.pengajuanMedia.edit',
            component: () => import('@/views/pages/marketing/pengajuan-media/PengajuanMediaEdit'),
            meta: {
              title: 'Edit Pengajuan Media',
              parent: 'marketing.pengajuanMedia', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Marketing' },
                { title: 'Pengajuan Media' },
                { title: 'Edit Pengajuan Media', active: true }
              ]
            }
          },
          {
            path: 'show/:idPengajuanMedia',
            name: 'marketing.pengajuanMedia.show',
            component: () => import('@/views/pages/marketing/pengajuan-media/PengajuanMediaShow'),
            meta: {
              title: 'Detail Pengajuan Media',
              parent: 'marketing.pengajuanMedia', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Marketing' },
                { title: 'Pengajuan Media' },
                { title: 'Detail Pengajuan Media', active: true }
              ]
            }
          }
        ]
      },
      {
        path: 'customerProspek',
        component: EmptyRouterView,
        children: [
          {
            path: '/',
            name: 'marketing.customerProspek',
            component: () => import('@/views/pages/marketing/customer-prospek/CustomerProspek'),
            meta: {
              title: 'Customer Prospek',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Marketing' },
                { title: 'Customer Prospek', active: true }
              ],
              can: ['view_customer_prospek']
            }
          }
        ]
      },
      {
        path: 'aktifitasMarketing',
        component: EmptyRouterView,
        children: [
          {
            path: '/',
            name: 'marketing.aktifitasMarketing',
            component: () => import('@/views/pages/marketing/aktifitas-marketing/AktifitasMarketing'),
            meta: {
              title: 'Aktifitas Marketing',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Marketing' },
                { title: 'Aktifitas Marketing', active: true }
              ],
              can: ['view_aktivitas_marketing']
            }
          },
          {
            path: 'add',
            name: 'marketing.aktifitasMarketing.add',
            component: () => import('@/views/pages/marketing/aktifitas-marketing/AktifitasMarketingAdd'),
            meta: {
              title: 'Tambah Aktifitas Marketing',
              parent: 'marketing.aktifitasMarketing', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Marketing' },
                { title: 'Aktifitas Marketing' },
                { title: 'Tambah Aktifitas Marketing', active: true }
              ]
            }
          },
          {
            path: 'edit/:idCustomerProspek',
            name: 'marketing.aktifitasMarketing.edit',
            component: () => import('@/views/pages/marketing/aktifitas-marketing/AktifitasMarketingEdit'),
            meta: {
              title: 'Edit Aktifitas Marketing',
              parent: 'marketing.aktifitasMarketing', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Marketing' },
                { title: 'Aktifitas Marketing' },
                { title: 'Edit Aktifitas Marketing', active: true }
              ]
            }
          }
        ]
      },
      {
        path: 'booking',
        component: EmptyRouterView,
        children: [
          {
            path: '/',
            name: 'marketing.booking',
            component: () => import('@/views/pages/marketing/booking/Booking'),
            meta: {
              title: 'Booking',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Marketing' },
                { title: 'Booking', active: true }
              ],
              can: ['view_booking']
            }
          },
          {
            path: 'add',
            name: 'marketing.booking.add',
            component: () => import('@/views/pages/marketing/booking/BookingAdd'),
            meta: {
              title: 'Tambah Booking',
              parent: 'marketing.booking', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Marketing' },
                { title: 'Booking', url: { name: 'marketing.booking' } },
                { title: 'Tambah Booking', active: true }
              ]
            }
          },
          {
            path: 'detail/:idBooking',
            name: 'marketing.booking.detail',
            component: () => import('@/views/pages/marketing/booking/BookingDetail'),
            meta: {
              title: 'Detail Booking',
              parent: 'marketing.booking', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Marketing' },
                { title: 'Booking', url: { name: 'marketing.booking' } },
                { title: 'Detail Booking', active: true }
              ]
            }
          },
          {
            path: 'edit/:idBooking',
            name: 'marketing.booking.edit',
            component: () => import('@/views/pages/marketing/booking/BookingEdit'),
            meta: {
              title: 'Edit Booking',
              parent: 'marketing.booking', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Marketing' },
                { title: 'Booking', url: { name: 'marketing.booking' } },
                { title: 'Edit Booking', active: true }
              ]
            }
          }
        ]
      },
      {
        path: 'kontrak',
        component: EmptyRouterView,
        children: [
          {
            path: '/',
            name: 'marketing.kontrak',
            component: () => import('@/views/pages/marketing/kontrak/Kontrak'),
            meta: {
              title: 'Kontrak',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Marketing' },
                { title: 'Kontrak', active: true }
              ],
              can: ['view_kontrak']
            }
          },
          {
            path: 'add',
            name: 'marketing.kontrak.add',
            component: () => import('@/views/pages/marketing/kontrak/KontrakAdd'),
            meta: {
              title: 'Tambah Kontrak',
              parent: 'marketing.kontrak', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Marketing' },
                { title: 'Kontrak', url: { name: 'marketing.kontrak' } },
                { title: 'Tambah Kontrak', active: true }
              ]
            }
          },
          {
            path: 'detail/:idKontrak',
            name: 'marketing.kontrak.detail',
            component: () => import('@/views/pages/marketing/kontrak/KontrakDetail'),
            meta: {
              title: 'Detail Kontrak',
              parent: 'marketing.kontrak', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Marketing' },
                { title: 'Kontrak', url: { name: 'marketing.kontrak' } },
                { title: 'Detail Kontrak', active: true }
              ]
            }
          }
        ]
      },
      {
        path: 'serahTerimaCustomer',
        component: EmptyRouterView,
        children: [
          {
            path: '/',
            name: 'marketing.serahTerimaCustomer',
            component: () => import('@/views/pages/marketing/serah-terima-customer/SerahTerimaCustomer'),
            meta: {
              title: 'Serah Terima Customer',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Marketing' },
                { title: 'Serah Terima Customer', active: true }
              ],
              can: ['view_stc']
            }
          }
        ]
      },
      {
        path: 'marketingFee',
        component: EmptyRouterView,
        children: [
          {
            path: 'setDealer',
            name: 'marketing.marketingFee.setDealer',
            component: () => import('@/views/pages/marketing/marketing-fee/SetDealer'),
            meta: {
              title: 'Set Dealer',
              parent: 'marketing.marketingFee',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Marketing' },
                { title: 'Marketing Fee' },
                { title: 'Set Dealer', active: true }
              ],
              can: ['view_dealer']
            }
          },
          {
            path: 'setFee',
            name: 'marketing.marketingFee.setFee',
            component: () => import('@/views/pages/marketing/marketing-fee/SetFee'),
            meta: {
              title: 'Set Fee',
              parent: 'marketing.marketingFee',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Marketing' },
                { title: 'Marketing Fee' },
                { title: 'Set Fee', active: true }
              ],
              can: ['view_fee']
            }
          }
        ]
      },
      {
        path: 'komisi',
        component: EmptyRouterView,
        children: [
          {
            path: '/',
            name: 'marketing.komisi',
            component: () => import('@/views/pages/marketing/komisi/Komisi'),
            meta: {
              title: 'Komisi',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Marketing' },
                { title: 'Komisi', active: true }
              ],
              can: ['view_komisi']
            }
          },
          {
            path: 'show/:idKomisi',
            name: 'marketing.komisi.show',
            component: () => import('@/views/pages/marketing/komisi/KomisiShow'),
            meta: {
              title: 'Detail Komisi',
              parent: 'marketing.komisi', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Marketing' },
                { title: 'Komisi', url: { name: 'marketing.komisi' } },
                { title: 'Detail Komisi', active: true }
              ]
            }
          }
        ]
      },

      /** ******************************** ESTATE ********************************** **/
      {
        path: 'ipl',
        component: EmptyRouterView,
        children: [
          {
            path: '/',
            name: 'estate.ipl',
            component: () => import('@/views/pages/estate/ipl/Ipl'),
            meta: {
              title: 'IPL Warga',
              parent: 'estate.ipl', // relate to menu-items slug
              noScroll: true,
              noPageContentTitle: true,
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Estate' },
                { title: 'IPL Warga', active: true }
              ],
              can: ['view_penerimaan_ipl_termin']
            }
          }
        ]
      },
      {
        path: 'komplainWarga',
        component: EmptyRouterView,
        children: [
          {
            path: '/',
            name: 'estate.komplainWarga',
            component: () => import('@/views/pages/estate/komplain-warga/KomplainWarga'),
            meta: {
              title: 'Komplain Warga',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Estate' },
                { title: 'Komplain Warga', active: true }
              ],
              can: ['view_komplain_warga']
            }
          }
        ]
      },
      {
        path: 'perijinanWarga',
        component: EmptyRouterView,
        children: [
          {
            path: '/',
            name: 'estate.perijinanWarga',
            component: () => import('@/views/pages/estate/perijinan-warga/PerijinanWarga'),
            meta: {
              title: 'Perijianan Warga',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Estate' },
                { title: 'Perijianan Warga', active: true }
              ],
              can: ['view_perijinan_warga']
            }
          }
        ]
      },
      {
        path: 'kebersihan',
        component: EmptyRouterView,
        children: [
          {
            path: '/',
            name: 'estate.kebersihan',
            component: () => import('@/views/pages/estate/kebersihan/Kebersihan'),
            meta: {
              title: 'Kebersihan',
              parent: 'estate.kebersihan', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Estate' },
                { title: 'Kebersihan', active: true }
              ],
              can: ['view_kebersihan']
            }
          },
          {
            path: 'add',
            name: 'estate.kebersihan.add',
            component: () => import('@/views/pages/estate/kebersihan/KebersihanAdd'),
            meta: {
              title: 'Tambah Kebersihan',
              parent: 'estate.kebersihan', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Estate' },
                { title: 'Kebersihan', url: { name: 'estate.kebersihan' } },
                { title: 'Tambah Kebersihan', active: true }
              ]
            }
          },
          {
            path: 'edit/:idKebersihan',
            name: 'estate.kebersihan.edit',
            component: () => import('@/views/pages/estate/kebersihan/KebersihanEdit'),
            meta: {
              title: 'Edit Kebersihan',
              parent: 'estate.kebersihan', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Estate' },
                { title: 'Kebersihan', url: { name: 'estate.kebersihan' } },
                { title: 'Edit Kebersihan', active: true }
              ]
            }
          }
        ]
      },
      {
        path: 'keamanan',
        component: EmptyRouterView,
        children: [
          {
            path: '/',
            name: 'estate.keamanan',
            component: () => import('@/views/pages/estate/keamanan/Keamanan'),
            meta: {
              title: 'Keamanan',
              parent: 'estate.keamanan', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Estate' },
                { title: 'Keamanan', active: true }
              ],
              can: ['view_keamanan']
            }
          },
          {
            path: 'add',
            name: 'estate.keamanan.add',
            component: () => import('@/views/pages/estate/keamanan/KeamananAdd'),
            meta: {
              title: 'Tambah Keamanan',
              parent: 'estate.keamanan', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Estate' },
                { title: 'Keamanan', url: { name: 'estate.keamanan' } },
                { title: 'Tambah Keamanan', active: true }
              ]
            }
          },
          {
            path: 'edit/:idKeamanan',
            name: 'estate.keamanan.edit',
            component: () => import('@/views/pages/estate/keamanan/KeamananEdit'),
            meta: {
              title: 'Edit Keamanan',
              parent: 'estate.keamanan', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Estate' },
                { title: 'Keamanan', url: { name: 'estate.keamanan' } },
                { title: 'Edit Keamanan', active: true }
              ]
            }
          },
          {
            path: 'show/:idKeamanan',
            name: 'estate.keamanan.show',
            component: () => import('@/views/pages/estate/keamanan/KeamananShow'),
            meta: {
              title: 'Detail Keamanan',
              parent: 'estate.keamanan', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Estate' },
                { title: 'Keamanan', url: { name: 'estate.keamanan' } },
                { title: 'Detail Keamanan', active: true }
              ]
            }
          }
        ]
      },
      {
        path: 'pos',
        component: EmptyRouterView,
        children: [
          {
            path: '/',
            name: 'estate.pos',
            component: () => import('@/views/pages/estate/pos/Pos'),
            meta: {
              title: 'POS',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Estate' },
                { title: 'POS', active: true }
              ],
              can: ['view_pos']
            }
          }
        ]
      },

      /** ***************************** PROCUREMENT ******************************** **/
      {
        path: 'pr',
        component: EmptyRouterView,
        children: [
          {
            path: '',
            name: 'procurement.pengadaan',
            component: () => import('@/views/pages/procurement/pengadaan/Pengadaan'),
            meta: {
              title: 'Purchase Request',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Procurement' },
                { title: 'Purchase Request', active: true }
              ],
              can: ['view_purchase_request']
            }
          },
          {
            path: 'add',
            name: 'procurement.pengadaan.add',
            component: () => import('@/views/pages/procurement/pengadaan/PengadaanAdd'),
            meta: {
              title: 'Tambah PR',
              parent: 'procurement.pengadaan', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Procurement' },
                { title: 'Purchase Request', url: { name: 'procurement.pengadaan' } },
                { title: 'Tambah PR', active: true }
              ]
            }
          },
          {
            path: 'edit/:idPengadaan',
            name: 'procurement.pengadaan.edit',
            component: () => import('@/views/pages/procurement/pengadaan/PengadaanEdit'),
            meta: {
              title: 'Edit PR',
              parent: 'procurement.pengadaan', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Procurement' },
                { title: 'Purchase Request', url: { name: 'procurement.pengadaan' } },
                { title: 'Edit PR', active: true }
              ]
            }
          },
          {
            path: 'show/:idPengadaan',
            name: 'procurement.pengadaan.show',
            component: () => import('@/views/pages/procurement/pengadaan/PengadaanShow'),
            props: { showButtonSigningAction: false },
            meta: {
              title: 'Detail PR',
              parent: 'procurement.pengadaan', // relate to menu-items slug
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Procurement' },
                { title: 'Purchase Request', url: { name: 'procurement.pengadaan' } },
                { title: 'Detail PR', active: true }
              ]
            }
          }
        ]
      },
      {
        path: 'reimburse',
        component: EmptyRouterView,
        children: [
          {
            path: '/',
            name: 'procurement.reimburse',
            component: () => import('@/views/pages/procurement/reimburse/Reimburse'),
            meta: {
              title: 'Reimburse',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Procurement' },
                { title: 'Reimburse', active: true }
              ],
              can: ['view_reimburse']
            }
          }
        ]
      },
      {
        path: 'cashAdvance',
        component: EmptyRouterView,
        children: [
          {
            path: '/',
            name: 'procurement.cashAdvance',
            component: () => import('@/views/pages/procurement/cash-advance/CashAdvance'),
            meta: {
              title: 'Cash Advance',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Procurement' },
                { title: 'Cash Advance', active: true }
              ],
              can: ['view_cash_advance']
            }
          }
        ]
      },
      {
        path: 'dlk',
        component: EmptyRouterView,
        children: [
          {
            path: '/',
            name: 'procurement.dlk',
            component: () => import('@/views/pages/procurement/dlk/Dlk'),
            meta: {
              title: 'Dinas Luar Kota',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Procurement' },
                { title: 'DLK', active: true }
              ],
              can: ['view_dlk']
            }
          }
        ]
      },

      /** ****************************** APPROVALS ********************************* **/
      {
        path: 'approvals',
        component: EmptyRouterView,
        children: [
          {
            path: 'purchaseRequestApproval',
            component: EmptyRouterView,
            children: [
              {
                path: '',
                name: 'approvals.purchaseRequestApproval',
                component: () => import('@/views/pages/approvals/purchase-request-approval/PurchaseRequestApproval'),
                meta: {
                  title: 'Purchase Request Approval',
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Approvals' },
                    { title: 'Purchase Request Approval', active: true }
                  ],
                  can: ['view_approval_purchase_request']
                }
              },
              {
                path: 'show/:idPengadaan',
                name: 'approvals.purchaseRequestApproval.show',
                component: () => import('@/views/pages/procurement/pengadaan/PengadaanShow'),
                props: { showButtonSigningAction: true },
                meta: {
                  title: 'Detail Purchase Request',
                  parent: 'approvals.purchaseRequestApproval', // relate to menu-items slug
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Approvals' },
                    { title: 'Purchase Request Approval' },
                    { title: 'Detail Purchase Request', active: true }
                  ],
                  can: ['view_approval_purchase_request']
                }
              }
            ]
          },
          {
            path: 'poKontrakApproval',
            component: EmptyRouterView,
            children: [
              {
                path: '/',
                name: 'approvals.poKontrakApproval',
                component: () => import('@/views/pages/approvals/po-kontrak-approval/PoKontrakApproval'),
                meta: {
                  title: 'PO Kontrak Approval',
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Approvals' },
                    { title: 'PO Kontrak Approval', active: true }
                  ],
                  can: ['view_approval_po_kontrak']
                }
              },
              {
                path: 'show/:idPo',
                name: 'approvals.poKontrakApproval.show',
                component: () => import('@/views/pages/approvals/po-kontrak-approval/PoKontrakApprovalShow'),
                meta: {
                  title: 'Detail Kontrak',
                  parent: 'approvals.poKontrakApproval', // relate to menu-items slug
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Approvals' },
                    { title: 'PO Kontrak Approval', url: { name: 'approvals.poKontrakApproval.show' } },
                    { title: 'Detail Kontrak', active: true }
                  ],
                  can: ['view_approval_po_kontrak']
                }
              }
            ]
          },
          {
            path: 'poTerminApproval',
            component: EmptyRouterView,
            children: [
              {
                path: '/',
                name: 'approvals.poTerminApproval',
                component: () => import('@/views/pages/approvals/po-termin-approval/PoTerminApproval'),
                meta: {
                  title: 'PO Termin Approval',
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Approvals' },
                    { title: 'PO Termin Approval', active: true }
                  ],
                  can: ['view_approval_po_termin']
                }
              },
              {
                path: 'show/:idPoD',
                name: 'approvals.poTerminApproval.show',
                component: () => import('@/views/pages/approvals/po-termin-approval/PoTerminApprovalShow'),
                meta: {
                  title: 'Detail Termin',
                  parent: 'approvals.poTerminApproval', // relate to menu-items slug
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Approvals' },
                    { title: 'PO Termin Approval', url: { name: 'approvals.poTerminApproval.show' } },
                    { title: 'Detail Termin', active: true }
                  ],
                  can: ['view_approval_po_termin']
                }
              }
            ]
          },
          {
            path: 'spkKontrakApproval',
            component: EmptyRouterView,
            children: [
              {
                path: '/',
                name: 'approvals.spkKontrakApproval',
                component: () => import('@/views/pages/approvals/spk-kontrak-approval/SpkKontrakApproval'),
                meta: {
                  title: 'SPP Kontrak Approval',
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Approvals' },
                    { title: 'SPP Kontrak Approval', active: true }
                  ],
                  can: ['view_approval_spp_kontrak']
                }
              },
              {
                path: 'show/:idSpk',
                name: 'approvals.spkKontrakApproval.show',
                component: () => import('@/views/pages/approvals/spk-kontrak-approval/SpkKontrakApprovalShow'),
                meta: {
                  title: 'Detail Kontrak',
                  parent: 'approvals.spkKontrakApproval', // relate to menu-items slug
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Approvals' },
                    { title: 'SPP Kontrak Approval', url: { name: 'approvals.spkKontrakApproval.show' } },
                    { title: 'Detail Kontrak', active: true }
                  ],
                  can: ['view_approval_spp_kontrak']
                }
              }
            ]
          },
          {
            path: 'spkProgressApproval',
            component: EmptyRouterView,
            children: [
              {
                path: '/',
                name: 'approvals.spkProgressApproval',
                component: () => import('@/views/pages/approvals/spk-progress-approval/SpkProgressApproval'),
                meta: {
                  title: 'SPP Progress Approval',
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Approvals' },
                    { title: 'SPP Progress Approval', active: true }
                  ],
                  can: ['view_approval_spp_progress']
                }
              },
              {
                path: 'show/:idProgressUnit',
                name: 'approvals.spkProgressApproval.show',
                component: () => import('@/views/pages/approvals/spk-progress-approval/SpkProgressApprovalShow'),
                meta: {
                  title: 'Detail Progress',
                  parent: 'approvals.spkProgressApproval', // relate to menu-items slug
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Approvals' },
                    { title: 'SPP Progress Approval', url: { name: 'approvals.spkProgressApproval.show' } },
                    { title: 'Detail Progress', active: true }
                  ],
                  can: ['view_approval_spp_progress']
                }
              }
            ]
          },
          {
            path: 'spkTerminApproval',
            component: EmptyRouterView,
            children: [
              {
                path: '/',
                name: 'approvals.spkTerminApproval',
                component: () => import('@/views/pages/approvals/spk-termin-approval/SpkTerminApproval'),
                meta: {
                  title: 'SPP Termin Approval',
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Approvals' },
                    { title: 'SPP Termin Approval', active: true }
                  ],
                  can: ['view_approval_spp_termin']
                }
              },
              {
                path: 'show/:idSpkD',
                name: 'approvals.spkTerminApproval.show',
                component: () => import('@/views/pages/approvals/spk-termin-approval/SpkTerminApprovalShow'),
                meta: {
                  title: 'Detail Termin',
                  parent: 'approvals.spkTerminApproval', // relate to menu-items slug
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Approvals' },
                    { title: 'SPP Termin Approval', url: { name: 'approvals.spkTerminApproval.show' } },
                    { title: 'Detail Termin', active: true }
                  ],
                  can: ['view_approval_spp_termin']
                }
              }
            ]
          },
          {
            path: 'spkAdjustApproval',
            component: EmptyRouterView,
            children: [
              {
                path: '/',
                name: 'approvals.spkAdjustApproval',
                component: () => import('@/views/pages/approvals/spk-adjust-approval/SpkAdjustApproval'),
                meta: {
                  title: 'SPP Adjust Approval',
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Approvals' },
                    { title: 'SPP Adjust Approval', active: true }
                  ]
                },
                can: ['view_approval_spp_adjust']
              },
              {
                path: 'show/:idSpkAdjust',
                name: 'approvals.spkAdjustApproval.show',
                component: () => import('@/views/pages/approvals/spk-adjust-approval/SpkAdjustApprovalShow'),
                meta: {
                  title: 'Detail SPP Adjust',
                  parent: 'approvals.spkAdjustApproval', // relate to menu-items slug
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Approvals' },
                    { title: 'SPP Adjust Approval', url: { name: 'approvals.spkAdjustApproval.show' } },
                    { title: 'Detail SPP Adjust', active: true }
                  ],
                  can: ['view_approval_spp_adjust']
                }
              }
            ]
          },
          {
            path: 'stkApproval',
            component: EmptyRouterView,
            children: [
              {
                path: '/',
                name: 'approvals.stkApproval',
                component: () => import('@/views/pages/approvals/stk-approval/StkApproval'),
                meta: {
                  title: 'STK Approval',
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Approvals' },
                    { title: 'STK Approval', active: true }
                  ],
                  can: ['view_approval_stk']
                }
              },
              {
                path: 'show/:idStk',
                name: 'approvals.stkApproval.show',
                component: () => import('@/views/pages/approvals/stk-approval/StkApprovalShow'),
                meta: {
                  title: 'Detail STK',
                  parent: 'approvals.stkApproval', // relate to menu-items slug
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Approvals' },
                    { title: 'STK Approval', url: { name: 'approvals.stkApproval.show' } },
                    { title: 'Detail STK', active: true }
                  ],
                  can: ['view_approval_stk']
                }
              }
            ]
          },
          {
            path: 'stcApproval',
            component: EmptyRouterView,
            children: [
              {
                path: '/',
                name: 'approvals.stcApproval',
                component: () => import('@/views/pages/approvals/stc-approval/StcApproval'),
                meta: {
                  title: 'STC Approval',
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Approvals' },
                    { title: 'STC Approval', active: true }
                  ],
                  can: ['view_approval_stc']
                }
              },
              {
                path: 'show/:idStc',
                name: 'approvals.stcApproval.show',
                component: () => import('@/views/pages/approvals/stc-approval/StcApprovalShow'),
                meta: {
                  title: 'Detail STC',
                  parent: 'approvals.stcApproval', // relate to menu-items slug
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Approvals' },
                    { title: 'STC Approval', url: { name: 'approvals.stcApproval.show' } },
                    { title: 'Detail STC', active: true }
                  ],
                  can: ['view_approval_stc']
                }
              }
            ]
          },
          {
            path: 'skupApproval',
            component: EmptyRouterView,
            children: [
              {
                path: '/',
                name: 'approvals.skupApproval',
                component: () => import('@/views/pages/approvals/skup-approval/SkupApproval'),
                meta: {
                  title: 'SKUP Approval',
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Approvals' },
                    { title: 'SKUP Approval', active: true }
                  ],
                  can: ['view_approval_skup']
                }
              },
              {
                path: 'show/:idBooking',
                name: 'approvals.skupApproval.show',
                component: () => import('@/views/pages/approvals/skup-approval/SkupApprovalShow'),
                meta: {
                  title: 'Detail Booking',
                  parent: 'approvals.skupApproval', // relate to menu-items slug
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Approvals' },
                    { title: 'SKUP Approval' },
                    { title: 'Detail Booking', active: true }
                  ],
                  can: ['view_approval_skup']
                }
              }
            ]
          },
          {
            path: 'paymentRequestApproval',
            component: EmptyRouterView,
            children: [
              {
                path: '/',
                name: 'approvals.paymentRequestApproval',
                component: () => import('@/views/pages/approvals/payment-request-approval/PaymentRequestApproval'),
                meta: {
                  title: 'Payment Request Approval',
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Approvals' },
                    { title: 'Payment Request Approval', active: true }
                  ],
                  can: ['view_approval_payment_request']
                }
              },
              {
                path: 'show/:idPaymentRequest',
                name: 'approvals.paymentRequestApproval.show',
                component: () => import('@/views/pages/approvals/payment-request-approval/PaymentRequestApprovalShow'),
                meta: {
                  title: 'Detail Payment Request',
                  parent: 'approvals.paymentRequestApproval', // relate to menu-items slug
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Approvals' },
                    { title: 'Payment Request Approval', url: { name: 'approvals.paymentRequestApproval.show' } },
                    { title: 'Detail Payment Request', active: true }
                  ],
                  can: ['view_approval_payment_request']
                }
              }
            ]
          },
          {
            path: 'komisiApproval',
            component: EmptyRouterView,
            children: [
              {
                path: '/',
                name: 'approvals.komisiApproval',
                component: () => import('@/views/pages/approvals/komisi-approval/KomisiApproval'),
                meta: {
                  title: 'Komisi Approval',
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Approvals' },
                    { title: 'Komisi Approval', active: true }
                  ],
                  can: ['view_approval_komisi']
                }
              },
              {
                path: 'show/:idKomisiPayment',
                name: 'approvals.komisiApproval.show',
                component: () => import('@/views/pages/approvals/komisi-approval/KomisiApprovalShow'),
                meta: {
                  title: 'Detail Komisi',
                  parent: 'approvals.komisiApproval', // relate to menu-items slug
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Approvals' },
                    { title: 'Komisi Approval', url: { name: 'approvals.komisiApproval.show' } },
                    { title: 'Detail Komisi', active: true }
                  ],
                  can: ['view_approval_komisi']
                }
              }
            ]
          },
          {
            path: 'reimburseApproval',
            component: EmptyRouterView,
            children: [
              {
                path: '/',
                name: 'approvals.reimburseApproval',
                component: () => import('@/views/pages/approvals/reimburse-approval/ReimburseApproval'),
                meta: {
                  title: 'Reimburse Approval',
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Approvals' },
                    { title: 'Reimburse Approval', active: true }
                  ],
                  can: ['view_approval_reimburse']
                }
              },
              {
                path: 'show/:idReimburse',
                name: 'approvals.reimburseApproval.show',
                component: () => import('@/views/pages/approvals/reimburse-approval/ReimburseApprovalShow'),
                meta: {
                  title: 'Detail Reimburse',
                  parent: 'approvals.reimburseApproval', // relate to menu-items slug
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Approvals' },
                    { title: 'Reimburse Approval', url: { name: 'approvals.reimburseApproval.show' } },
                    { title: 'Detail Reimburse', active: true }
                  ],
                  can: ['view_approval_reimburse']
                }
              }
            ]
          },
          {
            path: 'cashAdvanceApproval',
            component: EmptyRouterView,
            children: [
              {
                path: '/',
                name: 'approvals.cashAdvanceApproval',
                component: () => import('@/views/pages/approvals/cash-advance-approval/CashAdvanceApproval'),
                meta: {
                  title: 'Cash Advance Approval',
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Approvals' },
                    { title: 'Cash Advance Approval', active: true }
                  ],
                  can: ['view_approval_cash_advance']
                }
              },
              {
                path: 'show/:idCashAdvance',
                name: 'approvals.cashAdvanceApproval.show',
                component: () => import('@/views/pages/approvals/cash-advance-approval/CashAdvanceApprovalShow'),
                meta: {
                  title: 'Detail Cash Advance',
                  parent: 'approvals.cashAdvanceApproval', // relate to menu-items slug
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Approvals' },
                    { title: 'Cash Advance Approval', url: { name: 'approvals.cashAdvanceApproval.show' } },
                    { title: 'Detail Cash Advance', active: true }
                  ],
                  can: ['view_approval_cash_advance']
                }
              }
            ]
          },
          {
            path: 'perijinanWargaApproval',
            component: EmptyRouterView,
            children: [
              {
                path: '/',
                name: 'approvals.perijinanWargaApproval',
                component: () => import('@/views/pages/approvals/perijinan-warga-approval/PerijinanWargaApproval'),
                meta: {
                  title: 'Perijinan Warga Approval',
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Approvals' },
                    { title: 'Perijinan Warga Approval', active: true }
                  ],
                  can: ['view_approval_perijinan_warga']
                }
              },
              {
                path: 'show/:idPerijinanWarga',
                name: 'approvals.perijinanWargaApproval.show',
                component: () => import('@/views/pages/approvals/perijinan-warga-approval/PerijinanWargaApprovalShow'),
                meta: {
                  title: 'Detail Perijinan Warga',
                  parent: 'approvals.perijinanWargaApproval', // relate to menu-items slug
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Approvals' },
                    { title: 'Perijinan Warga Approval', url: { name: 'approvals.perijinanWargaApproval.show' } },
                    { title: 'Detail Perijinan Warga', active: true }
                  ],
                  can: ['view_approval_perijinan_warga']
                }
              }
            ]
          },
          {
            path: 'pengajuanMediaApproval',
            component: EmptyRouterView,
            children: [
              {
                path: '/',
                name: 'approvals.pengajuanMediaApproval',
                component: () => import('@/views/pages/approvals/pengajuan-media-approval/PengajuanMediaApproval'),
                meta: {
                  title: 'Pengajuan Media Approval',
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Approvals' },
                    { title: 'Pengajuan Media Approval', active: true }
                  ],
                  can: ['view_approval_pengajuan_media']
                }
              },
              {
                path: 'show/:idPengajuanMedia',
                name: 'approvals.pengajuanMediaApproval.show',
                component: () => import('@/views/pages/approvals/pengajuan-media-approval/PengajuanMediaApprovalShow'),
                meta: {
                  title: 'Detail Pengajuan Media',
                  parent: 'approvals.pengajuanMediaApproval', // relate to menu-items slug
                  breadcrumb: [
                    { title: 'Home', url: '/' },
                    { title: 'Approvals' },
                    { title: 'Pengajuan Media Approval', url: { name: 'approvals.pengajuanMediaApproval.show' } },
                    { title: 'Detail Pengajuan Media', active: true }
                  ],
                  can: ['view_approval_pengajuan_media']
                }
              }
            ]
          }

        ]
      },

      /** ***************************** ACCOUNTING ********************************* **/
      {
        path: 'postingTransaksi',
        component: EmptyRouterView,
        children: [
          {
            path: '',
            name: 'accounting.postingTransaksi',
            component: () => import('@/views/pages/accounting/posting-transaksi/PostingTransaksi'),
            meta: {
              title: 'Posting Transaksi',
              parent: 'accounting.postingTransaksi', // relate to menu-items slug
              noScroll: true,
              noPageContentTitle: true,
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Accounting' },
                { title: 'Posting Transaksi', active: true }
              ],
              can: ['view_posting_transaksi']
            }
          }
        ]
      },
      {
        path: 'generalJurnal',
        component: EmptyRouterView,
        children: [
          {
            path: '',
            name: 'accounting.generalJurnal',
            component: () => import('@/views/pages/accounting/general-jurnal/GeneralJurnal'),
            meta: {
              title: 'General Jurnal',
              parent: 'accounting.generalJurnal', // relate to menu-items slug
              noScroll: true,
              noPageContentTitle: true,
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Accounting' },
                { title: 'General Jurnal', active: true }
              ],
              can: ['view_general_jurnal']
            }
          }
        ]
      },
      {
        path: 'gl',
        component: EmptyRouterView,
        children: [
          {
            path: '',
            name: 'accounting.generalLedger',
            component: () => import('@/views/pages/accounting/general-ledger/GeneralLedger'),
            meta: {
              title: 'General Ledger',
              parent: 'accounting.generalLedger', // relate to menu-items slug
              noScroll: true,
              noPageContentTitle: true,
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Accounting' },
                { title: 'General Ledger', active: true }
              ],
              can: ['view_general_ledger']
            }
          }
        ]
      },
      {
        path: 'pengakuanPendapatan',
        component: EmptyRouterView,
        children: [
          {
            path: '',
            name: 'accounting.pengakuanPendapatan',
            component: () => import('@/views/pages/accounting/pengakuan-pendapatan/PengakuanPendapatan'),
            meta: {
              title: 'Pengakuan Pendapatan',
              parent: 'accounting.pengakuanPendapatan', // relate to menu-items slug
              noScroll: true,
              noPageContentTitle: true,
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Accounting' },
                { title: 'Pengakuan Pendapatan', active: true }
              ],
              can: ['view_pengakuan_pendapatan']
            }
          }
        ]
      },
      {
        path: 'ap',
        component: EmptyRouterView,
        children: [
          {
            path: '',
            name: 'accounting.ap',
            component: () => import('@/views/pages/accounting/account-payable/ap/Ap'),
            meta: {
              title: 'AP (Account Payable)',
              parent: 'accounting.ap', // relate to menu-items slug
              noScroll: true,
              noPageContentTitle: true,
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Accounting' },
                { title: 'Account Payable', active: true }
              ],
              can: ['view_ap']
            }
          }
        ]
      },
      {
        path: 'apOther',
        component: EmptyRouterView,
        children: [
          {
            path: '',
            name: 'accounting.apOther',
            component: () => import('@/views/pages/accounting/account-payable/ap-other/ApOther'),
            meta: {
              title: 'AP (Account Payable) Other',
              parent: 'accounting.apOther', // relate to menu-items slug
              noScroll: true,
              noPageContentTitle: true,
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Accounting' },
                { title: 'Account Payable Other', active: true }
              ],
              can: ['view_ap_other']
            }
          }
        ]
      },
      {
        path: 'apSaldoAwal',
        component: EmptyRouterView,
        children: [
          {
            path: '',
            name: 'accounting.apSaldoAwal',
            component: () => import('@/views/pages/accounting/account-payable/ap-saldo-awal/ApSaldoAwal'),
            meta: {
              title: 'AP Saldo Awal',
              parent: 'accounting.apSaldoAwal', // relate to menu-items slug
              noScroll: true,
              noPageContentTitle: true,
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Accounting' },
                { title: 'AP Saldo Awal', active: true }
              ],
              can: ['view_ap_saldo_awal']
            }
          }
        ]
      },
      {
        path: 'ar',
        component: EmptyRouterView,
        children: [
          {
            path: '',
            name: 'accounting.ar',
            component: () => import('@/views/pages/accounting/account-receivable/ar/Ar'),
            meta: {
              title: 'AR (Account Receivable)',
              parent: 'accounting.ar', // relate to menu-items slug
              noScroll: true,
              noPageContentTitle: true,
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Accounting' },
                { title: 'Account Receivable', active: true }
              ],
              can: ['view_ar']
            }
          }
        ]
      },
      {
        path: 'arOther',
        component: EmptyRouterView,
        children: [
          {
            path: '',
            name: 'accounting.arOther',
            component: () => import('@/views/pages/accounting/account-receivable/ar-other/ArOther'),
            meta: {
              title: 'AR (Account Receivable) Other',
              parent: 'accounting.arOther', // relate to menu-items slug
              noScroll: true,
              noPageContentTitle: true,
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Accounting' },
                { title: 'Account Receivable Other', active: true }
              ],
              can: ['view_ar_other']
            }
          }
        ]
      },
      {
        path: 'arSaldoAwal',
        component: EmptyRouterView,
        children: [
          {
            path: '',
            name: 'accounting.arSaldoAwal',
            component: () => import('@/views/pages/accounting/account-receivable/ar-saldo-awal/ArSaldoAwal'),
            meta: {
              title: 'AR Saldo Awal',
              parent: 'accounting.arSaldoAwal', // relate to menu-items slug
              noScroll: true,
              noPageContentTitle: true,
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Accounting' },
                { title: 'AR Saldo Awal', active: true }
              ],
              can: ['view_ar_saldo_awal']
            }
          }
        ]
      },
      {
        path: 'apAging',
        component: EmptyRouterView,
        children: [
          {
            path: '',
            name: 'accounting.apAging',
            component: () => import('@/views/pages/accounting/account-payable/ap-aging/ApAging'),
            meta: {
              title: 'AP Aging',
              parent: 'accounting.apAging', // relate to menu-items slug
              noScroll: true,
              noPageContentTitle: true,
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Accounting' },
                { title: 'AP Aging', active: true }
              ],
              can: ['view_ap_aging']
            }
          }
        ]
      },
      {
        path: 'arAging',
        component: EmptyRouterView,
        children: [
          {
            path: '',
            name: 'accounting.arAging',
            component: () => import('@/views/pages/accounting/account-receivable/ar-aging/ArAging'),
            meta: {
              title: 'AR Aging',
              parent: 'accounting.arAging', // relate to menu-items slug
              noScroll: true,
              noPageContentTitle: true,
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Accounting' },
                { title: 'AR Aging', active: true }
              ],
              can: ['view_ar_aging']
            }
          }
        ]
      },
      {
        path: 'prepaid',
        component: EmptyRouterView,
        children: [
          {
            path: '',
            name: 'accounting.prepaid',
            component: () => import('@/views/pages/accounting/prepaid/Prepaid'),
            meta: {
              title: 'Prepaid',
              parent: 'accounting.prepaid', // relate to menu-items slug
              noScroll: true,
              noPageContentTitle: true,
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Accounting' },
                { title: 'Prepaid', active: true }
              ],
              can: ['view_prepaid']
            }
          }
        ]
      },
      {
        path: 'fixedAsset',
        component: EmptyRouterView,
        children: [
          {
            path: '',
            name: 'accounting.fixedAsset',
            component: () => import('@/views/pages/accounting/fixed-asset/FixedAsset'),
            meta: {
              title: 'Fixed Asset',
              parent: 'accounting.fixedAsset', // relate to menu-items slug
              noScroll: true,
              noPageContentTitle: true,
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Accounting' },
                { title: 'Fixed Asset', active: true }
              ],
              can: ['view_fixed_asset']
            }
          }
        ]
      },
      {
        path: 'accountBudgeting',
        component: EmptyRouterView,
        children: [
          {
            path: '',
            name: 'accounting.accountBudgeting',
            component: () => import('@/views/pages/accounting/setup/account-budgeting/AccountBudgeting'),
            meta: {
              title: 'Account Budgeting',
              parent: 'accounting.accountBudgeting', // relate to menu-items slug
              noScroll: true,
              noPageContentTitle: true,
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Accounting' },
                { title: 'Setup' },
                { title: 'Account Budgeting', active: true }
              ],
              can: ['view_account_budgeting']
            }
          }
        ]
      },
      {
        path: 'setupPrepaid',
        component: EmptyRouterView,
        children: [
          {
            path: '',
            name: 'accounting.setupPrepaid',
            component: () => import('@/views/pages/accounting/setup/setup-prepaid/SetupPrepaid'),
            meta: {
              title: 'Setup Prepaid',
              parent: 'accounting.setupPrepaid', // relate to menu-items slug
              noScroll: true,
              noPageContentTitle: true,
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Accounting' },
                { title: 'Setup' },
                { title: 'Setup Prepaid', active: true }
              ],
              can: ['view_setup_prepaid']
            }
          }
        ]
      },
      {
        path: 'setupFixedAsset',
        component: EmptyRouterView,
        children: [
          {
            path: '',
            name: 'accounting.setupFixedAsset',
            component: () => import('@/views/pages/accounting/setup/setup-fixed-asset/SetupFixedAsset'),
            meta: {
              title: 'Setup Fixed Asset',
              parent: 'accounting.setupFixedAsset', // relate to menu-items slug
              noScroll: true,
              noPageContentTitle: true,
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Accounting' },
                { title: 'Setup' },
                { title: 'Setup Fixed Asset', active: true }
              ],
              can: ['view_setup_fixed_asset']
            }
          }
        ]
      },
      {
        path: 'setupReport',
        component: EmptyRouterView,
        children: [
          {
            path: '',
            name: 'accounting.setupReport',
            component: () => import('@/views/pages/accounting/setup/setup-report/SetupReport'),
            meta: {
              title: 'Setup Report',
              parent: 'accounting.setupReport', // relate to menu-items slug
              noScroll: true,
              noPageContentTitle: true,
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Accounting' },
                { title: 'Setup' },
                { title: 'Setup Report', active: true }
              ],
              can: ['view_setup_report']
            }
          }
        ]
      },

      /** ****************************** GENERAL *********************************** **/
      {
        path: 'accessControl',
        component: EmptyRouterView,
        children: [
          {
            path: 'accessControl',
            name: 'general.accessControl',
            component: () => import('@/views/pages/general/access-control/AccessControl'),
            meta: {
              title: 'Access Control',
              parent: 'general.accessControl', // relate to menu-items slug
              noScroll: true,
              noPageContentTitle: true,
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'General' },
                { title: 'Access Control', active: true }
              ],
              can: ['view_access_control']
            }
          }
        ]
      },
      {
        path: 'systemParameters',
        component: EmptyRouterView,
        children: [
          {
            path: 'systemParameters',
            name: 'general.systemParameters',
            component: () => import('@/views/pages/general/system_parameter/SystemParameter'),
            meta: {
              title: 'System Parameters',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'General' },
                { title: 'System Parameters', active: true }
              ],
              can: ['view_system_parameters']
            }
          }
        ]
      },
      {
        path: 'activityLog',
        component: EmptyRouterView,
        children: [
          {
            path: 'activityLog',
            name: 'general.activityLog',
            component: () => import('@/views/pages/general/activity_log/ActivityLog'),
            meta: {
              title: 'Activity Log',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'General' },
                { title: 'Activity Log', active: true }
              ],
              can: ['view_activity_log']
            }
          }
        ]
      },
      {
        path: 'systemApprovals',
        component: EmptyRouterView,
        children: [
          {
            path: '/',
            name: 'general.systemApprovals',
            component: () => import('@/views/pages/general/system_approvals/SystemApprovals'),
            meta: {
              title: 'System Approvals',
              parent: 'general.systemApprovals', // relate to menu-items slug
              noScroll: true,
              noPageContentTitle: true,
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'General' },
                { title: 'System Approvals', active: true }
              ],
              can: ['view_system_approvals']
            }
          }
        ]
      }

    ]
  },

  // ====================== LAYOUT FULL ====================//
  {
    path: '',
    component: () => import('@/views/layouts/MasterFull'),
    children: [
      {
        path: '/403',
        name: '403',
        meta: { title: 'Unauthorized' },
        component: () => import('@/views/pages/errors/403')
      },
      {
        path: '/404',
        name: '404',
        meta: { title: 'Not Found' },
        component: () => import('@/views/pages/errors/404')
      },
      {
        path: '/login',
        name: 'login',
        meta: { onlyGuest: true, title: 'Login' },
        component: () => import('@/views/pages/auth/Login')
      },
      {
        path: '/pos/stationOrder',
        name: 'estate.pos.stationOrder',
        component: () => import('@/views/pages/estate/pos/station-order/StationOrder'),
        meta: {
          title: 'Station Order'
        }
      },
      {
        path: 'report',
        component: EmptyRouterView,
        children: [
          {
            path: '/',
            name: 'report',
            component: () => import('@/views/pages/reports/main/Report'),
            meta: {
              title: 'Reports',
              can: ['view_report']
            }
          },
          {
            path: 'add',
            name: 'report.add',
            component: () => import('@/views/pages/reports/add/ReportAdd'),
            meta: {
              title: 'Tambah Report',
              can: ['create_report']
            }
          },
          {
            path: 'edit/:idReport',
            name: 'report.edit',
            component: () => import('@/views/pages/reports/edit/ReportEdit'),
            meta: {
              title: 'Edit Report',
              can: ['update_report']
            }
          },
          {
            path: 'design/:idReport',
            name: 'report.design',
            component: () => import('@/views/pages/reports/design/ReportDesign'),
            meta: {
              title: 'Design Report',
              can: ['update_report']
            }
          },
          {
            path: 'accessControl',
            name: 'report.accessControl',
            component: () => import('@/views/pages/reports/acl/ReportAccessControl'),
            meta: {
              title: 'Report Access Control',
              can: ['view_report_access_control']
            }
          }
        ]
      }
    ]
  },

  // ============== REDIRECT UNKNOWN ROUTES ================//
  {
    path: '*',
    redirect: { name: '404' }
  }

]
